<template>
  <div @click="onClick" class="body">
    <div class="body card">
      <div class="card-content">
        <div class="content">
          <p>
            <span class="type" :style="'--n:5000'">{{ text }} </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      text: `Kijk naar de TV.`,
    };
  },
  methods: {
    onClick() {
      this.$socket.emit("continue");
    },
  },
  sockets: {
    waiting: function () {
      navigator.vibrate([500, 500]);
      this.text = `Tik om door te gaan...`;
    },
    continue: function () {
      this.text = `Kijk naar de TV.`;
    },
    startgame: function (game) {
      this.$router.push("/" + game);
    },
    hints: function () {
      this.$router.push("/hints");
    },
  },
};
</script>

<style scoped>
/* Full size centered #body */
.body {
  position: relative;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

/* Frosted glass background #card */
.card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(240px);
}

/* Card content big, centered */
.card-content {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50vw;
  transform: translate(-50%, -50%);
  text-align: center;
}

/* Paragraph text with typewriter effect */
p {
  font-family: "Courier New", Courier, monospace;
  font-size: 1.5rem;
  white-space: pre-line !important;
  margin: 0 auto;
  letter-spacing: 0.15em;
}

.type {
  color: #0000;
  background: linear-gradient(-90deg, #000 5px, #0000 0) 10px 0,
    linear-gradient(#000 0 0) 0 0;
  background-size: calc(var(--n) * 1ch) 200%;
  -webkit-background-clip: padding-box, text;
  background-clip: padding-box, text;
  background-repeat: no-repeat;
  animation: b 1s infinite steps(1),
    t calc(var(--n) * 0.03s) steps(var(--n)) forwards;
}

@keyframes t {
  from {
    background-size: 0 200%;
  }
}
@keyframes b {
  50% {
    background-position: 0 -100%, 0 0;
  }
}
</style>
