<template>
  <div id="app">
    <!-- Background image, zoom to fit -->
    <img class="background" src="@/assets/background.png" />
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
    };
  },
  sockets: {
    success: function () {
      this.$buefy.toast.open({
        message: "Verbonden met de gameserver!",
        type: "is-success",
        queue: false,
      });
    },
  },
  mounted() {
    
  },
};
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Zoom to fit background image */
.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

::-webkit-scrollbar {
  display: none;
}
</style>
