import Vue from 'vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import App from './App.vue'
import router from './router'
import VueSocketIO from 'vue-socket.io'

Vue.config.productionTip = false

Vue.use(Buefy)
Vue.use(new VueSocketIO({
  debug: true,
  connection: 'https://sinterklaas.chrisvanchip.repl.co',
  options: { transports: ['websocket', 'polling', 'flashsocket'] }
}))

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
