import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/FinishView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/memory',
    name: 'Memory',
    component: () => import(/* webpackChunkName: "memory" */ '../views/MemoryView.vue')
  },
  {
    path: '/lijnen',
    name: 'Lijnen',
    component: () => import(/* webpackChunkName: "lijnen" */ '../views/LijnenView.vue')
  },
  {
    path: '/vragen',
    name: 'Vragen',
    component: () => import(/* webpackChunkName: "vragen" */ '../views/VragenView.vue')
  },
  {
    path: '/hints',
    name: 'Hints',
    component: () => import(/* webpackChunkName: "hints" */ '../views/HintsView.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
